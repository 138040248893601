import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Container, Row, Col } from "reactstrap";

function League(){
  const [trackedLeagues, setTrackedLeagues] = useState([]);

  useEffect(() => {
    fetch("/api/trackedLeagues").then((response) => {
      if(response.ok){
        response.json().then((json) =>{
          setTrackedLeagues(json);
        })
      }
      else{
        //TODO error out!
      }

    });
  }, []);

  return (
    <Container
      className="bg-light border"
      fluid
    >
      <Row>
        <h3>Tracked Leagues</h3>
      </Row>

      <Row>
        <Col
          className="bg-light border"
          md={{
            offset: 2,
            size: 8
          }}
          sm="12"
        >
          
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>League Id</th>
              <th>League Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {trackedLeagues.map((item,index) => {
              const link = `/leagues/${item.id}`
              return <tr key={index}>
                <th scope="row">{index+1}</th>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td><Link to={link}>View Details</Link></td>
              </tr>
            })}
          </tbody>
        </Table>
        </Col>
      </Row>

    </Container>
  );
}

export default League;