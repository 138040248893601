import { useEffect, useState } from "react";
import { Table, Container, Row, Col, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
function Manager(){
  const [manager, setManager] = useState({});
  const [gameWeeks, setGameWeeks] = useState([]);
  const { managerId } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch("/api/manager/" + managerId + "/results/").then((response) => {
      if(response.ok){
        response.json().then((json) =>{
          setGameWeeks(json);
          setLoading(false);
        })
      }
      else{
        //TODO error out!
      }

    });
    fetch("/api/manager/" + managerId).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setManager(json);
        })
      }
      else {
        //TODO error out!
      }
    });
  }, [managerId]);

  return (
    <Container
      className="bg-light border"
      fluid
    >
      <Row>
        <Col
          className="bg-light border"
          md={{
            size: 8
          }}
          sm="12"
        >
          <h3>Manager Name : {manager.player_first_name}  {manager.player_last_name}</h3>
          <h3>Team Name : {manager.name}</h3>
          <h3>Overall Points : {manager.summary_overall_points}</h3>
        </Col>
        <Col
          className="bg-light border"
          md={{
            size: 4
          }}
          sm="12"
        >
        </Col>
      </Row>

      {loading ? <div>Loading ..</div>:
      <Row>
        <Col
          className="bg-light border"
          md={{
            offset: 1,
            size: 10
          }}
          sm="12"
        >
          
        <Table striped bordered hover responsive>
          <thead>
            <tr key={0}>
              <th>GameWeek #</th>
              <th>Transfers</th>
              <th>Team Value</th>
              <th>GameWeek Points</th>
              <th>Bench Points</th>
              <th>Cumulative Points</th>
            </tr>
          </thead>
          <tbody>
                {gameWeeks.map((item,index) => {
              return <tr key={index+1}>
                <th scope="row">{item.event}</th>
                <td>{item.event_transfers}</td>
                <td>{item.value/100}</td>
                <td>{item.points}</td>
                <td>{item.points_on_bench}</td>
                <td>{item.total_points}</td>
              </tr>
            })}
          </tbody>
        </Table>
        </Col>
      </Row>
      }
    </Container>
  );
}

export default Manager;