import { useEffect, useState } from "react";
import { Table, Container, Row, Col, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
function League(){
  const [league, setLeague] = useState({});
  const { leagueId } = useParams();
  const [loading, setLoading] = useState(true);
  const [gameWeeks, setGameWeeks] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const getRowClass = (rowIndex) => {
    if (rowIndex === 1) {
      return "table-success";
    }
    else if (rowIndex <= 3) {
      return "table-primary";
    }
    return "table-light";
  }

  useEffect(() => {
    
    fetch("/api/leagues/" + leagueId).then((response) => {
      if(response.ok){
        response.json().then((json) =>{
          setLeague(json);
          setLoading(false);
        })
      }
      else{
        //TODO error out!
      }

    });
  }, [leagueId]);
  useEffect(() => {
    fetch("/api/gameWeeks").then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          setGameWeeks(json);
        })
      }
      else {
        //TODO error out!
      }
    });
  }, []);
  return (
    <Container
      className="bg-light border"
      fluid
    >
      <Row>
        <Col
          className="bg-light border"
          md={{
            size: 8
          }}
          sm="12"
        >
          <h3>League : {league.name}</h3>
          <h3>Overall Table</h3>
        </Col>
        <Col
          className="bg-light border"
          md={{
            size: 4
          }}
          sm="12"
        >
          <Dropdown isOpen={dropdownOpen} toggle={toggle} >
            <DropdownToggle caret>Select Game Week</DropdownToggle>
            <DropdownMenu>
              <DropdownItem><Link to={`/leagues/${leagueId}`}>Overall Ranking</Link></DropdownItem>
              {gameWeeks.map((item,index) => {
                const link = `/leagues/${leagueId}/history/${item.id}`
                  return (item.finished || item.is_current) ? <DropdownItem key={index}><Link to={link}>{item.name}{item.is_current ? ' (Current)' : null}</Link></DropdownItem> : null
              })}
            </DropdownMenu>

          </Dropdown>
        </Col>
      </Row>

      {loading ? <div>Loading ..</div>:
      <Row>
        <Col
          className="bg-light border"
          md={{
            offset: 2,
            size: 8
          }}
          sm="12"
        >
          
        <Table striped bordered hover responsive>
          <thead>
            <tr key={0}>
              <th>#</th>
              <th>Manager #</th>
              <th>Team Name</th>
              <th>Manager Name</th>
              <th>Overall Points</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
              {league.managers.map((item,index) => {
                const link = `/manager/${item.id}/`
                return <tr key={index + 1} className={getRowClass(index + 1)} >
                <th scope="row">{index+1}</th>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.player_first_name} {item.player_last_name}</td>
                <td>{item.summary_overall_points}</td>
                <td><Link to={link}>Manager Info</Link></td>
              </tr>
            })}
          </tbody>
        </Table>
        </Col>
      </Row>
      }
    </Container>
  );
}

export default League;