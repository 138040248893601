import Home from "./components/Home";
import League from "./components/League";
import LeagueHistory from "./components/LeagueHistory";
import Manager from "./components/Manager";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    index: false,
    path: "/leagues/:leagueId",
    element: <League />
  },
  {
    index: false,
    path: "/leagues/:leagueId/history/:gameWeekId",
    element: <LeagueHistory />
  },
  {
    index: false,
    path: "/manager/:managerId",
    element: <Manager />
  },
];

export default AppRoutes;
